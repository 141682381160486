import React from 'react';

const FightFireWithMusic = props => {
  return (
    <>
      <section id="album">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 padding_all">
              <div className="col-sm-4">
                <div className="middle_2">
                  <div className="ih-item square effect15 top_to_bottom">
                    <div className="img"><img src="img/fightfirewithmusic/fight-fire-with-music.jpg"
                                              alt="Fight Fire with Music logo"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="contact_1">
                  <h1 className="text-center">Fight Fire with . . . MUSIC!</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 padding_all">
              <div className="tour_2">
                <p>
                  Many of us here in South Florida enjoy our thriving local music scene: attending shows, performing
                  together, collaborating on different projects, and generally united in the appreciation of how
                  important music has been in each of our lives.
                </p>
                <p>
                  We're also acutely aware that on the opposite coast, the wildfires in greater Los Angeles have had a
                  devastating impact on the lives of countless individuals, including music industry professionals.
                </p>
                <p>
                  Five local bands are teaming up with Double Roads Tavern for a fundraiser to help musicians and
                  other industry professionals who have suffered losses to their homes, instruments, equipment, and
                  livelihoods. The event will take place:
                </p>
                <p className="text-center">
                  <h2>Sunday, February 23, beginning at 2 PM</h2>
                </p>
                <p className="text-center">
                  at
                </p>
                <p className="text-center">
                  <h2>Double Roads Tavern, 103 S US Highway 1, Jupiter</h2>
                </p>
                <p>
                  and will include live performances by local bands <a href="https://backwatersaintz.com"
                                                                       target="_blank" rel="noreferrer">Backwater
                  Saintz</a>, <a href="https://theblunatics.com" target="_blank" rel="noreferrer">The Blunatics</a>, <a
                  href="https://enablers.live" target="_blank" rel="noreferrer">The Enablers</a>, <a
                  href="https://lovemedicineband.com" target="_blank" rel="noreferrer">Love Medicine</a>, and <a
                  href="https://nipandtuckband.com" target="_blank" rel="noreferrer">Nip & Tuck</a>.
                  All monies raised will be donated through the Los Angeles Fire Relief Effort
                  To Support Music Professionals, launched jointly by The Recording Academy and MusiCares.
                </p>
                <p>
                  In addition to a prize raffle and 50/50 raffle, there will be a silent auction; we'll update this page
                  often with a list of prizes and auction items.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 padding_all">
              <div className="tour_2 clearfix">
                <p>
                  Silent auction items will be on display at 2:00; music starts at 3:00.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="team" className="fightfire_page">
        <div className="container">
          <hr/>
          <div className="row">
            <div className="col-sm-12 padding_all">
              <h2 className="text-center">Silent Auction Items</h2>
              <p className="text-center">Each auction item will have a separate bidding sheet, and a minimum bid.</p>
              <p className="text-center">Click on the images below for details of each item.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/bugginoutboat.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>3-Hour Boat Picnic</h3>
                    <p>Enjoy a 3-hour boat picnic on the Intracoastal Waterway with Captain Dean Adler on his boat
                      Buggin' Out! Up
                      to 6 guests.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">3-hour Boat Picnic</h3>
                <p className="text-center">Buggin' Out</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/cbreezeboat.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>1/2 Day Fishing Trip</h3>
                    <p>Enjoy a half-day fishing trip with Captain Mike Calabrese on his boat C-breeze! Up
                      to 4 guests.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">1/2 Day Fishing Trip</h3>
                <p className="text-center">C-breeze</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/winebasket.jpg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Wine Basket</h3>
                    <p>An assortment of wine bottles selected and donated by Starla and Clayton Perrey.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Wine Basket</h3>
                <p className="text-center">Starla and Clayton Perrey</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/dbWellbeing.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Personal Training Session</h3>
                    <p>A 1-hour session with Debi Borger, experienced fitness, nutrition, and life coach.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Personal Training Session</h3>
                <p className="text-center">Debi Borger</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/ChrissyBrahney.jpg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Photo Session</h3>
                    <p>A photo session (or professional head shots) by Chrissy Brahney Photography.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Photo Session</h3>
                <p className="text-center">Chrissy Brahney Photography</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/granatdesign.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Technical SEO Audit</h3>
                    <p>A technical SEO audit for a business website, by Granat Design. Matt Granat will help you get
                      better search engine results for your business!</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Technical SEO Audit</h3>
                <p className="text-center">Granat Design</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/theblunatics.jpg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Private Party Band Performance</h3>
                    <p>The Blunatics will perform a 3 hour show at your private party. Date and time to be mutually
                      agreed upon.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">The Blunatics</h3>
                <p className="text-center">3-hour Band Performance</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/theenablers.jpg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Private Party Band Performance</h3>
                    <p>The Enablers will perform a 3 hour show at your private party. Date and time to be mutually
                      agreed upon.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">The Enablers</h3>
                <p className="text-center">3-hour Band Performance</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/logo-narrow.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Private Party Band Performance</h3>
                    <p>Love Medicine will perform a 3 hour show at your private party. Date and time to be mutually
                      agreed upon.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Love Medicine</h3>
                <p className="text-center">3-hour Band Performance</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/JupiterWaterTours.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Full Boat Water Tour</h3>
                    <p>Enjoy a water tour for your private group (up to 15 people) by Jupiter Water Tours.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Full Boat Water Tour</h3>
                <p className="text-center">Jupiter Water Tours</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/KSCLogo.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>2-Tank Scuba Dive</h3>
                    <p>You and a buddy will join Kyalami Scuba Club for a 2-tank dive. (Or if just one person, go out
                      with them two different times!)</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">2-Tank Scuba Dive for Two People</h3>
                <p className="text-center">Kyalami Scuba Club</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/harleybenton.jpeg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Bass Guitar</h3>
                    <p>Harley Benton TB-70 VS Bass Guitar with gig bag, donated by Ed Spenser. This is a copy of a
                      Gibson Thunderbird (made in China).</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Bass Guitar</h3>
                <p className="text-center">Ed Spenser</p>
              </div>
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-sm-12 padding_all">
              <h2 className="text-center">Prize Raffle Items</h2>
              <p className="text-center">Purchase prize tickets and apply them to whichever items you'd like to win.
                Use
                multiple tickets for
                the same item to increase your chances!</p>
              <p className="text-center">Click on the images below for details of each item.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/prizeraffle/JAMlogo.jpg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Guitar Lesson</h3>
                    <p>A 1-hour guitar lesson with the one and only Gary Frost! Value: $75.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Guitar Lesson with Gary Frost</h3>
                <p className="text-center">Jupiter Academy of Music</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/prizeraffle/dr_logo.jpeg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Gift Certificates</h3>
                    <p>TWO prizes! Each is a Double Roads gift certificate for $100.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Gift Certificates</h3>
                <p className="text-center">Double Roads Tavern</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/prizeraffle/greencompassglobal.jpeg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Gift Basket of CBD Supplements</h3>
                    <p>An assortment of CBD supplements from Green Compass Global, valued at $75.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Gift Basket of CBD Supplements</h3>
                <p className="text-center">Green Compass Global</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/JupiterWaterTours.png' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Water Tour</h3>
                    <p>Two seats on a water tour by Jupiter Water Tours. Value: $150</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Water Tour</h3>
                <p className="text-center">Jupiter Water Tours</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/prizeraffle/pbnative.jpeg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Vintage Prints</h3>
                    <p>TWO prizes! Each is a vintage print from palmbeach Native, worth $30.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Vintage Prints</h3>
                <p className="text-center">palmbeach Native</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/prizeraffle/giftbasket.jpeg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Ladies' Gift Basket</h3>
                    <p>An assortment of items curated and donated by Gina Marie Cobb. Value: $100.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Ladies' Gift Basket</h3>
                <p className="text-center">Gina Marie Cobb</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/auction/BluelineLogo.jpeg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Paddleboard / Kayak Rental</h3>
                    <p>2 Hour paddleboard or kayak rental for 2 people. Value: $86.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Paddleboard / Kayak Rental</h3>
                <p className="text-center">Blueline Surf & Paddle Co.</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="team_1">
                <div className="ih-item square effect7">
                  <div className="img">
                    <img src='img/fightfirewithmusic/prizeraffle/giftbasket.jpeg' alt="img"/>
                  </div>
                  <div className="info">
                    <h3>Band "Swag"</h3>
                    <p>An assortment of merchandise (t-shirts, hats, stickers) from all of the bands performing at the
                      event. Value: $125.</p>
                  </div>
                </div>
              </div>
              <div className="team_2">
                <h3 className="text-center">Band "Swag"</h3>
                <p className="text-center">All Five Bands</p>
              </div>
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-sm-12 padding_all">
              <div className="tour_2 clearfix">
                <p>
                  Please join us for this special event and help us "Fight Fire with MUSIC!"
                </p>
                <p>
                  If you are unable to attend the event but still wish to donate, please use this link:
                </p>
                <p className="text-center">
                  <a href="https://donor.musicares.org/-/XMFHNKXM" target="_blank"
                     rel="noreferrer">https://donor.musicares.org/-/XMFHNKXM</a>
                </p>
              </div>
            </div>
          </div>
          <div className="row white">
            <div className="col-sm-12 padding_all">
              <div className="middle_2">
                {/*<div className="col-sm-1"></div>*/}
                <div className="col-sm-2">
                  <div className="ih-item square effect15">
                    <a
                      href="https://backwatersaintz.com"
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className="img"><img src="img/fightfirewithmusic/bwsaintzlogo.jpg" alt="Backwater Saintz"/>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="ih-item square">
                    <a
                      href="https://theblunatics.com"
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className="img"><img src="img/fightfirewithmusic/theblunatics.jpg" alt="The Blunatics"/>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="ih-item square effect15 top_to_bottom">
                    <a
                      href="https://enablers.live"
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className="img"><img src="img/fightfirewithmusic/theenablers.jpg" alt="The Enablers"/></div>
                    </a>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="ih-item square effect15 top_to_bottom">
                    <a
                      href="https://lovemedicineband.com"
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className="img"><img src="img/logo-wide.png" alt="Love Medicine"/></div>
                    </a>
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="ih-item square effect15 top_to_bottom">
                    <a
                      href="https://nipandtuckband.com"
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div className="img"><img src="img/fightfirewithmusic/niptuck.png" alt="Nip & Tuck"/></div>
                    </a>
                  </div>
                </div>
                {/*<div className="col-sm-1"></div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FightFireWithMusic;
